import classNames from "classnames";
import { useFeature } from "flagged";
import { Form, Formik, FormikProps } from "formik";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import ReactTooltip from "react-tooltip";
import { object, string } from "yup";

import ManageWorkflowWrapper from "./ManageWorkflowWrapper";
import FailedRedNotificationIcon from "components/icons/FailedRedNotificationIcon";
import Info from "components/icons/Info";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import staticTableStyle from "components/support/api-guide/introduction/introduction-view.scss";
import StaticTable from "components/support/api-guide/StaticTable";
import DateCell from "components/table/DateCell";
import tableStyle from "components/table/table.scss";
import TextWithTooltip from "components/table/TextWithTooltip";
import { TextBlock } from "components/typography/textBlock/TextBlock";
import ConfirmationModal from "components/workflows/ConfirmationModal";
import JsonEditorView from "components/workflows/manage-workflow-dialog/json-editor/JsonEditorView";
import style from "components/workflows/manage-workflow-dialog/manage-workflow-dialog.scss";
import VisualEditorView, {
    WorkflowEditor,
} from "components/workflows/manage-workflow-dialog/visual-editor/VisualEditorView";
import WasmVisualEditorView, { ActionType } from "components/workflows/wasm-visual-editor/WasmVisualEditorView";
import { compareEditorVersionDtos } from "components/workflows/WorkflowUtil";
import { AUTH_WORKFLOW_EDIT } from "domain/authority";
import { FeatureLicenseType } from "domain/users";
import { VersionsData, Workflow, WorkflowEntityType } from "domain/workflows";
import { FLAG_CONNECTED_WORKFLOWS, FLAG_NEW_WORKFLOW_EDITOR } from "services/feature/FeatureFlagService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { userService } from "services/user/users/UserService";
import { userSessionService } from "services/user/UserSessionService";
import {
    ManifestWorkflowEditor,
    Profile,
    profileToFeatureLicenseMap,
    profileToNameMap,
    SaveSuccessResponse,
    WorkflowDto,
    WorkflowService,
    workflowService,
} from "services/workflows/WorkflowService";
import { StoreState } from "store";
import { setUser } from "store/user";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";
import { hasOnlyValidWorkflowCharacters } from "utils/commonFunctions";
import { formatTimestamp } from "utils/format";
import { logger } from "utils/logging";

import crossIcon from "assets/images/icons/cross.svg";

import testIds from "testIds.json";

enum DialogState {
    FETCHING_WORKFLOW_EDITORS,
    FETCHING_WORKFLOW_EDITORS_FAILED,
    FETCHING_WORKFLOW,
    FETCHING_WORKFLOW_FAILED,
    SELECTING_WORKFLOW_EDITOR,
    SELECTING_NEW_WORKFLOW_EDITOR,
    EDITING,
    ASYNC_SAVING_WORKFLOW,
    ASYNC_SAVING_WORKFLOW_SUCCEEDED,
    ASYNC_SAVING_WORKFLOW_FAILED,
    SYNC_SAVING_WORKFLOW,
    SYNC_SAVING_WORKFLOW_SUCCEEDED,
    SYNC_SAVING_WORKFLOW_FAILED,
}

enum TabIndex {
    VISUAL_EDITOR,
    JSON_EDITOR,
    VERSION_LIST,
}

interface Props {
    confirmCancelVisible?: boolean;
    editorGeneration: string;
    entityType: WorkflowEntityType;
    onCancel: (doCancel: boolean) => void;
    onUpdateParentView: () => void;
    refreshCount: number;
    setDynamicSize: (initial: boolean) => void;
    setEditorGeneration: (generation: string) => void;
    setShowConfirmCancelDialog?: (show: boolean) => void;
    workflowService: WorkflowService;
    isCreate?: boolean;
    isEdit?: boolean;
    name?: string;
    parentWorkflowsCount?: number;
    product?: string;
    tenantName?: string;
    tryNewEditor?: boolean;
    updateWorkflowUuid?: string;
    version?: string;
    workflowUuid?: string;
}

interface WorkflowFormValues {
    name: string;
}

interface CreateWorkflowFormValues extends WorkflowFormValues {
    product: Profile;
    version: string;
}

const WORKFLOW_NAME_MAX_LENGTH = 255;

const mapState = (state: StoreState) => ({
    user: state.userReducer.user,
});
const connector = connect(mapState, { setUser });
const ManageWorkflowDialog: React.FunctionComponent<Props & ConnectedProps<typeof connector>> = (props) => {
    const { t } = useTranslation();
    const [previousVersionConfirmation, setPreviousVersionConfirmation] = React.useState(false);
    const [saveAsWorkflow, setSaveAsWorkflow] = React.useState(false);
    const [modifiedVersion, setModifiedVersion] = React.useState<VersionsData | undefined>(undefined);
    const [previousVersionToConfirm, setPreviousVersionToConfirm] = React.useState<VersionsData | undefined>(undefined);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [workflow, setWorkflow] = useState<Workflow | undefined>(undefined);
    const [workflowUuid, setWorkflowUuid] = useState(props.workflowUuid);
    const [versions, setVersions] = React.useState<VersionsData[]>([]);
    const [workflowEditors, setWorkflowEditors] = useState<ManifestWorkflowEditor[]>([]);
    const [updateWorkflowEditors, setUpdateWorkflowEditors] = useState<ManifestWorkflowEditor[]>([]);
    const workflowEditor = useRef<WorkflowEditor | undefined>(undefined);
    const [dialogState, setDialogState] = React.useState(DialogState.FETCHING_WORKFLOW);
    const [selectedProfile, setSelectedProfile] = React.useState<Profile | "">("");
    const [selectedVersion, setSelectedVersion] = React.useState<string | "">("");
    const [selectedNewVersion, setSelectedNewVersion] = React.useState<string | "">("");
    const [optionList, setOptionList] = React.useState<JSX.Element[] | JSX.Element | undefined>(undefined);
    const [updateOptionList, setUpdateOptionList] = React.useState<JSX.Element[] | undefined>(undefined);
    const [selectedTab, setSelectedTab] = React.useState(TabIndex.VISUAL_EDITOR);
    const [lastModification, setLastModification] = React.useState<{ username: string; timestamp: string } | undefined>(
        undefined
    );
    const [saveError, setSaveError] = React.useState<{ field: string; message: string } | string | undefined>(
        undefined
    );
    const [workflowName, setWorkflowName] = React.useState("");
    const [workflowNameInputValue, setWorkflowNameInputValue] = React.useState("");
    const [workflowSaveAsNameInputValue, setWorkflowSaveAsNameInputValue] = React.useState("");
    const [initialWorkflow, setInitialWorkflow] = React.useState<Workflow | undefined>(undefined);
    const [frontendValidationError, setFrontendValidationError] = React.useState<string | undefined>(undefined);
    const [backendValidationErrors, setBackendValidationErrors] = React.useState<string | undefined>(undefined);
    const [updateNewWorkflowVisibility, setUpdateNewWorkflowVisibility] = React.useState(false);
    const tenantDetails = useSelector(
        (state: StoreState) => state.tenantDetailsReducer.stack[state.tenantDetailsReducer.stack.length - 1]
    );
    const userDetails = useSelector((state: StoreState) => state.userReducer.user);
    const [showUpdateOldGenStatusInStore, setShowUpdateOldGenStatusInStore] = useState(
        userDetails?.showUpdateOldGenWorkflow
    );
    const [loading, setLoading] = React.useState(false);
    const [showUpdateWorkflowDialogButton, setShowUpdateWorkflowDialogButton] = React.useState(false);
    const newWorkflowEditor = useFeature(FLAG_NEW_WORKFLOW_EDITOR);
    const connectedWorkflows = useFeature(FLAG_CONNECTED_WORKFLOWS);
    const fetchWorkflow = (uuid: string): Promise<WorkflowDto> => {
        setDialogState(DialogState.FETCHING_WORKFLOW);

        const abortController = new AbortController();
        abortControllers.push(abortController);

        if (modifiedVersion !== undefined) {
            return props.workflowService.fetchPreviouslySaveVersion(uuid, modifiedVersion.id, abortController);
        }
        return props.workflowService.fetch(uuid, abortController);
    };

    const preventNameEdit = !connectedWorkflows || props.parentWorkflowsCount === 0 ? false : true;
    const fetchWorkflowEditors = (profile: Profile): Promise<ManifestWorkflowEditor[]> => {
        setDialogState(DialogState.FETCHING_WORKFLOW);

        const abortController = new AbortController();
        abortControllers.push(abortController);

        return props.workflowService.fetchWorkflowEditors(profile, abortController);
    };

    const fetchWorkflowVersionList = (uuid: string) => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        props.workflowService
            .fetchVersionList(uuid, abortController)
            .then((versionsData) => {
                setVersions(versionsData.versions);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setDialogState(DialogState.FETCHING_WORKFLOW_EDITORS_FAILED);
                }
            });
    };

    const saveWorkflow = (workflow: Workflow, uuid?: string): Promise<SaveSuccessResponse> => {
        const abortController = new AbortController();
        abortControllers.push(abortController);

        if (typeof uuid === "string") {
            return props.workflowService.update(uuid, workflow, abortController);
        }

        return props.workflowService.create(workflow, undefined, abortController);
    };

    const extractWorkflowProfile = (targetWorkflow: Workflow) => {
        return (targetWorkflow.editorMetadata?.profile?.toLowerCase() as Profile) ?? Profile.BMDE;
    };

    const deduceSource = (): Workflow | undefined => {
        if (TabIndex.VISUAL_EDITOR === selectedTab) {
            // The visual editor doesn't support updating the state so the workflow data needs to be queried
            try {
                const json = workflowEditor.current?.serializeWorkflow();
                if (typeof json === "string") {
                    return JSON.parse(json);
                }
            } catch (e) {
                logger.error("Visual editor failed to serialize the workflow during save: " + e);
            }
        } else {
            return workflow;
        }
        logger.error("Deduce workflow source failed");
    };

    const handleSaveClick = () => {
        const source = deduceSource();

        if (source !== undefined) {
            setDialogState(DialogState.ASYNC_SAVING_WORKFLOW);
            setSaveError(undefined);
            saveWorkflow(source, workflowUuid)
                .then((response) => {
                    setWorkflowUuid(response.uuid);
                    setLastModification({ username: response.modifiedBy, timestamp: response.modified });
                    fetchWorkflowVersionList(response.uuid);
                    setDialogState(DialogState.ASYNC_SAVING_WORKFLOW_SUCCEEDED);
                })
                .catch((error) => {
                    try {
                        const response = JSON.parse(error.message);
                        setSaveError({ field: response.fieldName, message: response.errorMessage });
                    } catch (e) {
                        // This error message doesn't have field details
                    }

                    setDialogState(DialogState.ASYNC_SAVING_WORKFLOW_FAILED);
                });
        } else {
            setSaveError(t("ManageWorkflowDialog.saveWorkflow.failureMessage"));
            setDialogState(DialogState.ASYNC_SAVING_WORKFLOW_FAILED);
        }
    };

    const handleSaveCloseClick = () => {
        const source = deduceSource();
        props.setDynamicSize(true);
        if (source !== undefined) {
            setDialogState(DialogState.SYNC_SAVING_WORKFLOW);
            saveWorkflow(source, workflowUuid)
                .then(() => {
                    setDialogState(DialogState.SYNC_SAVING_WORKFLOW_SUCCEEDED);
                })
                .catch(() => {
                    setDialogState(DialogState.SYNC_SAVING_WORKFLOW_FAILED);
                });
        } else {
            setSaveError(t("ManageWorkflowDialog.saveWorkflow.failureMessage"));
            setDialogState(DialogState.SYNC_SAVING_WORKFLOW_SUCCEEDED);
        }
        props.setShowConfirmCancelDialog && props.setShowConfirmCancelDialog(false);
    };

    const handleSaveAsClick = () => {
        usageStatisticsService.sendEvent({
            category: Category.WORKFLOW,
            action: Action.SAVE_WORKFLOW_AS,
        });
        props.setDynamicSize(true);
        const source = deduceSource();
        if (source !== undefined) {
            setDialogState(DialogState.SYNC_SAVING_WORKFLOW);
            source.name = workflowSaveAsNameInputValue;
            saveWorkflow(source)
                .then(() => {
                    setDialogState(DialogState.SYNC_SAVING_WORKFLOW_SUCCEEDED);
                })
                .catch((error) => {
                    try {
                        const response = JSON.parse(error.message);
                        setSaveError({ field: response.fieldName, message: response.errorMessage });
                    } catch (e) {
                        // This error message doesn't have field details
                    }
                    setDialogState(DialogState.SYNC_SAVING_WORKFLOW_FAILED);
                });
        } else {
            props.setDynamicSize(true);
            setSaveError(t("ManageWorkflowDialog.saveWorkflow.failureMessage"));
            setDialogState(DialogState.SYNC_SAVING_WORKFLOW_SUCCEEDED);
        }
    };

    const handleTabSelect = (index: number, lastIndex: number) => {
        if (index !== lastIndex) {
            if (TabIndex.VISUAL_EDITOR === lastIndex) {
                const source = deduceSource();
                if (source !== undefined) {
                    setWorkflow(source);
                    setWorkflowName(source.name);
                    setWorkflowNameInputValue(source.name);
                }
            }
            setSelectedTab(index as TabIndex);
        }
        return true;
    };

    const handleSelectedProfileChanged = (event: React.FormEvent<HTMLSelectElement>) => {
        setSelectedProfile(event.currentTarget.value as Profile);
    };

    const handleSelectedVersionChanged = (event: React.FormEvent<HTMLSelectElement>) => {
        setSelectedVersion(event.currentTarget.value);
        setSelectedProfile(selectedProfile as Profile);
    };

    const handleSelectedNewVersionChanged = (event: React.FormEvent<HTMLSelectElement>) => {
        setSelectedNewVersion(event.currentTarget.value);
        setSelectedProfile(
            props.product === Profile.BMDE || props.product === Profile.BMDE_DIAGNOSTIC ? Profile.BMDE : Profile.BDE
        );
    };

    const confirmWorkflowEditorSelection = () => {
        const checkProfile = selectedProfile === Profile.BMDE_DIAGNOSTIC ? Profile.BMDE : selectedProfile;
        const editor = workflowEditors.find((each) => each.profile == checkProfile && each.version == selectedVersion);
        props.setShowConfirmCancelDialog && props.setShowConfirmCancelDialog(true);
        setDialogState(DialogState.FETCHING_WORKFLOW);
        props.setEditorGeneration(editor?.editorGeneration === "V2" ? "V2" : "V1");
        props.setDynamicSize(false);
        if (editor?.editorGeneration == "V2") {
            setDialogState(DialogState.EDITING);
        } else {
            setTimeout(() => {
                setDialogState(DialogState.EDITING);
            }, 1000);
        }
    };

    const confirmNewWorkflowEditorSelection = () => {
        props.setShowConfirmCancelDialog && props.setShowConfirmCancelDialog(true);
        setDialogState(DialogState.FETCHING_WORKFLOW);
        props.setEditorGeneration("V2");
        props.setDynamicSize(false);
        setWorkflowUuid(props.workflowUuid);
        setTimeout(() => {
            setDialogState(DialogState.EDITING);
        }, 1000);
    };

    const handleBackToEditorClick = () => {
        props.setDynamicSize(false);
        setWorkflow(initialWorkflow);
        setSelectedTab(TabIndex.VISUAL_EDITOR);
        setDialogState(DialogState.EDITING);
    };

    const validateWorkflowName = (workflowName: string) => {
        setFrontendValidationError(undefined);
        if (!hasOnlyValidWorkflowCharacters(workflowName)) {
            setBackendValidationErrors(undefined);
            setFrontendValidationError(t("WorkflowsTable.actions.create.validation.nameSpecialCharacters"));
        }
        if (workflowName.length > WORKFLOW_NAME_MAX_LENGTH) {
            setBackendValidationErrors(undefined);
            setFrontendValidationError(t("WorkflowsTable.actions.create.validation.maxLength"));
        }
    };

    const handleWorkflowNameInputChange = (event: React.FormEvent<HTMLInputElement>) => {
        validateWorkflowName(event.currentTarget.value);
        setWorkflowNameInputValue(event.currentTarget.value);
    };

    const handleSaveAsInputChange = (event: React.FormEvent<HTMLInputElement>) => {
        setWorkflowSaveAsNameInputValue(event.currentTarget.value);
    };

    const handleWorkflowNameInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            handleWorkflowNameChange();
        }
    };

    /**
     * Handles state change when the user has changed the workflow's name in the input element. The new name is updated
     * to the workflow stored in the state.
     */
    const handleWorkflowNameChange = () => {
        const source = deduceSource();
        if (source !== undefined) {
            setWorkflow({
                ...source,
                name: workflowNameInputValue,
            });
        }
        setWorkflowName(workflowNameInputValue);
    };

    /**
     * Handles state change when the user has used one of the editors to change the workflow. The name in the workflow
     * is updated to the workflow name stored in the state.
     */
    const handleWorkflowChange = (workflow: Workflow) => {
        setWorkflow(workflow);
        if (workflow !== undefined) {
            setWorkflowNameInputValue(workflow.name);
            setWorkflowSaveAsNameInputValue(workflow.name);
            setWorkflowName(workflow.name);
        } else {
            const source = deduceSource();
            if (source !== undefined) {
                setWorkflow({
                    ...source,
                    name: workflowNameInputValue,
                });
            }
        }
    };

    /**
     * Handles state change where the workflow name might have been changed both in the name input and in the editor.
     * The name in the name field takes priority unless it is not set there.
     */
    const handleWorkflowChangeWithNameSync = (workflow: Workflow) => {
        if (workflow === undefined) {
            return;
        }

        const nameFromEditor = workflow.name.trim();
        const nameFromInput = workflowName.trim();

        const nameSetWithEditor = nameFromEditor.length > 0;
        const nameSetWithInput = nameFromInput.length > 0;

        if (nameSetWithEditor && !nameSetWithInput) {
            // The name was set with the visual editor but not with the name input.
            // Sync the name from the output of the visual editor to the name input
            setWorkflowName(workflow.name);
        } else if (!nameSetWithEditor && nameSetWithInput) {
            // The name wasn't set with the visual editor but was set with the name input.
            // Sync the name from the input to the output of the visual editor.
            workflow.name = nameFromInput;
        } else if (nameSetWithInput) {
            workflow.name = nameFromInput;
        }

        setWorkflow(workflow);
        return workflow;
    };

    useEffect(() => {
        if (typeof workflowUuid === "string") {
            fetchWorkflow(workflowUuid)
                .then((workflow) => {
                    handleWorkflowChange(workflow.workflow);
                    setLastModification({ username: workflow.modified_by_username, timestamp: workflow.modified_date });
                    fetchWorkflowVersionList(workflowUuid);
                    fetchWorkflowEditors(Profile.ALL)
                        .then((workflowEditors) => {
                            setWorkflowEditors(
                                newWorkflowEditor
                                    ? props.tryNewEditor
                                        ? workflowEditors.filter((each) => each.editorGeneration === "V2")
                                        : workflowEditors
                                    : workflowEditors.filter((each) => each.editorGeneration !== "V2")
                            );
                            setSelectedTab(TabIndex.VISUAL_EDITOR);
                            props.setDynamicSize(false);
                            props.setShowConfirmCancelDialog && props.setShowConfirmCancelDialog(true);
                            setDialogState(DialogState.EDITING);
                            setInitialWorkflow(workflow.workflow);
                        })
                        .catch(() => {
                            setDialogState(DialogState.FETCHING_WORKFLOW_EDITORS_FAILED);
                        });
                })
                .catch(() => setDialogState(DialogState.FETCHING_WORKFLOW_FAILED));
        } else {
            fetchWorkflowEditors(Profile.ALL)
                .then((workflowEditors) => {
                    setWorkflowEditors(
                        newWorkflowEditor
                            ? props.tryNewEditor
                                ? workflowEditors.filter((each) => each.editorGeneration === "V2")
                                : workflowEditors
                            : workflowEditors.filter((each) => each.editorGeneration !== "V2")
                    );
                    // Set default SelectedProfile based on tenant's workflow licences
                    const filteredProfiles = Object.values(Profile).filter((each) => {
                        const featureLicense = profileToFeatureLicenseMap.get(each);
                        return (
                            Profile.ALL !== each &&
                            featureLicense != null &&
                            tenantDetails.featureLicenses.includes(featureLicense)
                        );
                    });

                    if (filteredProfiles.length === 0) {
                        throw new Error("Tenant has no feature licences");
                    }

                    setDialogState(
                        props.isCreate
                            ? DialogState.SELECTING_WORKFLOW_EDITOR
                            : DialogState.SELECTING_NEW_WORKFLOW_EDITOR
                    );
                })
                .catch(() => setDialogState(DialogState.FETCHING_WORKFLOW_EDITORS_FAILED));
        }
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, [modifiedVersion]);

    useEffect(() => {
        const sortedEditors = workflowEditors
            .filter((editor) =>
                selectedProfile == Profile.BDE ? editor.profile === selectedProfile : editor.profile != Profile.BDE
            )
            .sort(compareEditorVersionDtos);

        if (sortedEditors.length == 0) {
            return;
        }
        const deduplicatedVersions = sortedEditors.filter(
            (editor, index, self) => index === self.findIndex((each) => each.version === editor.version)
        );

        if (props.updateWorkflowUuid) {
            const updateVersions = workflowEditors
                .filter(
                    (each) =>
                        each.editorGeneration === "V2" &&
                        each.profile ===
                            (props.product === Profile.BMDE || props.product === Profile.BMDE_DIAGNOSTIC
                                ? Profile.BMDE
                                : props.product)
                )
                .sort(compareEditorVersionDtos);

            const updateEditorVersions: ManifestWorkflowEditor[] = [];
            for (let i = 0; i < updateVersions.length; i++) {
                if (updateVersions[i].version === props.version) {
                    break;
                }
                updateEditorVersions.push(updateVersions[i]);
            }

            setUpdateWorkflowEditors(updateEditorVersions);
            setUpdateOptionList(
                updateEditorVersions.map((editor) => (
                    <option key={editor.version + ":" + editor.profile}>{editor.version}</option>
                ))
            );
        }

        // Templates and template sub-workflows are always created with the latest editor version.
        const optionList =
            props.entityType === "WORKFLOW" ? (
                deduplicatedVersions.map((editor) => (
                    <option key={editor.version + ":" + editor.profile}>{editor.version}</option>
                ))
            ) : (
                <option key={deduplicatedVersions[0].version + ":" + deduplicatedVersions[0].profile}>
                    {deduplicatedVersions[0].version}
                </option>
            );

        setOptionList(optionList);
    }, [selectedProfile, workflowEditors]);

    const handleVersionChanged = (
        <Modal
            isOpen={previousVersionConfirmation}
            hideModal={() => setPreviousVersionConfirmation(false)}
            modalTitle={t("ManageWorkflowDialog.confirmVersionChangeDialog.title")}
        >
            <div className={style.resultContainer}>
                <TextBlock>
                    {t("ManageWorkflowDialog.confirmVersionChangeDialog.confirmationText", {
                        modified: formatTimestamp(previousVersionToConfirm?.modified ?? ""),
                    })}
                </TextBlock>
                <div className={style.buttonContainer}>
                    <button
                        className={buttons.deleteButtonWithoutIcon}
                        onClick={() => {
                            usageStatisticsService.sendEvent({
                                category: Category.WORKFLOW,
                                action: Action.LOAD_WORKFLOW_VERSION,
                            });
                            setModifiedVersion(previousVersionToConfirm);
                            setPreviousVersionConfirmation(false);
                        }}
                        data-testid={testIds.common.confirmationDialog.confirmButton}
                    >
                        {t("ManageWorkflowDialog.confirmVersionChangeDialog.openPreviousVersionButton")}
                    </button>
                    <button
                        className={buttons.primaryButtonWithoutIcon}
                        onClick={() => {
                            setPreviousVersionConfirmation(false);
                        }}
                        data-testid={testIds.common.dialog.closeButton}
                    >
                        {t("ManageWorkflowDialog.confirmVersionChangeDialog.keepEditingCurrentVersionButton")}
                    </button>
                </div>
            </div>
        </Modal>
    );

    React.useEffect(() => {
        if (DialogState.ASYNC_SAVING_WORKFLOW_FAILED === dialogState) {
            setFrontendValidationError(undefined);
            setBackendValidationErrors(
                saveError !== undefined
                    ? typeof saveError === "string"
                        ? saveError
                        : t("ManageWorkflowDialog.saveWorkflow.failureInputMessage", saveError)
                    : t("ManageWorkflowDialog.saveWorkflow.failureMessage")
            );
        }
    }, [dialogState]);

    let saveStatus = <></>;
    const confirmationModal = (
        <ConfirmationModal
            confirmCancelVisible={props.confirmCancelVisible}
            onCancel={props.onCancel}
            onUpdateParentView={props.onUpdateParentView}
            setShowConfirmCancelDialog={props.setShowConfirmCancelDialog}
        />
    );
    switch (dialogState) {
        case DialogState.ASYNC_SAVING_WORKFLOW:
            saveStatus = (
                <div className={style.loadingMessage}>{t("ManageWorkflowDialog.saveWorkflow.loadingMessage")}</div>
            );
            break;
        case DialogState.ASYNC_SAVING_WORKFLOW_FAILED:
            saveStatus = (
                <div className={style.failureMessage}>
                    <img src={crossIcon} />
                    {saveError !== undefined
                        ? typeof saveError === "string"
                            ? saveError
                            : t("ManageWorkflowDialog.saveWorkflow.failureMessageDetails", saveError)
                        : t("ManageWorkflowDialog.saveWorkflow.failureMessage")}
                </div>
            );
            break;
        case DialogState.ASYNC_SAVING_WORKFLOW_SUCCEEDED:
        case DialogState.EDITING:
            saveStatus =
                lastModification === undefined ? (
                    <div className={style.statusMessage}>{t("ManageWorkflowDialog.saveWorkflow.notSaved")}</div>
                ) : (
                    <div className={style.statusMessage}>
                        {t("ManageWorkflowDialog.saveWorkflow.lastSaved", {
                            timestamp: formatTimestamp(lastModification.timestamp),
                            username: lastModification.username,
                        })}
                    </div>
                );
            break;
    }

    const editingActionsDisabled =
        frontendValidationError !== undefined ||
        !userSessionService.userHasAllAuthorities([AUTH_WORKFLOW_EDIT]) ||
        !(
            [
                DialogState.EDITING,
                DialogState.ASYNC_SAVING_WORKFLOW_FAILED,
                DialogState.ASYNC_SAVING_WORKFLOW_SUCCEEDED,
            ].includes(dialogState) && workflowName.length > 0
        );

    const updateShowOldGenUpdateStatus = () => {
        setShowUpdateOldGenStatusInStore(false);
        setLoading(true);
        const uuid = userDetails?.uuid;
        if (!uuid) {
            return;
        }
        const abortController = new AbortController();
        abortControllers.push(abortController);
        userService
            .updateShowOldGenWorkflowUpdate(uuid, abortController)
            .then(() => {
                if (props.user) {
                    const user = { ...props.user, showUpdateOldGenWorkflow: false };
                    props.setUser(user);
                    userSessionService.storeUser(user);
                }
                setShowUpdateWorkflowDialogButton(true);
            })
            .catch(() => {
                logger.error("Failed to update user.");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleSaveAsDialogs = (
        <Modal
            isOpen={saveAsWorkflow}
            hideModal={() => setSaveAsWorkflow(false)}
            modalTitle={t("ManageWorkflowDialog.saveAsWorkflow.title")}
        >
            <Formik
                initialValues={{ name: "" }}
                onSubmit={confirmWorkflowEditorSelection}
                validationSchema={object().shape({
                    name: string()
                        .max(WORKFLOW_NAME_MAX_LENGTH, t("WorkflowsTable.actions.create.validation.nameMaxLength"))
                        .test(
                            "Has only valid characters",
                            t("WorkflowsTable.actions.create.validation.nameSpecialCharacters"),
                            (value) => hasOnlyValidWorkflowCharacters(value)
                        ),
                })}
            >
                {({ values, errors, handleChange, isValid }: FormikProps<WorkflowFormValues>) => {
                    return (
                        <Form>
                            <div>
                                <span>
                                    <input
                                        autoFocus
                                        id="saveAsWorkflowName"
                                        name="name"
                                        data-testid={testIds.workArea.workflows.manageWorkflowDialog.nameInput}
                                        type="text"
                                        value={values.name}
                                        className={classNames(form.input, style.workflowNameInput)}
                                        placeholder={t("ManageWorkflowDialog.workflowName")}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            handleChange(e);
                                            handleSaveAsInputChange(e);
                                        }}
                                        maxLength={WORKFLOW_NAME_MAX_LENGTH}
                                        disabled={!saveAsWorkflow && preventNameEdit}
                                    />
                                </span>
                                <div className={classNames(form.error, form.errorMultiLine)}>{errors.name}</div>
                                <div className={classNames(form.buttonContainer)}>
                                    <button
                                        className={buttons.primaryButtonWithoutIcon}
                                        onClick={handleSaveAsClick}
                                        disabled={!isValid || editingActionsDisabled}
                                        data-testid={testIds.common.confirmationDialog.confirmButton}
                                    >
                                        {t("Common.saveClose")}
                                    </button>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </Modal>
    );

    const updateNewWorkflow = (
        <ManageWorkflowWrapper
            modalTitle={"ManageWorkflowDialog.updateWorkflowDialog.title"}
            modalVisibility={updateNewWorkflowVisibility}
            onUpdate={props.onUpdateParentView}
            workflowService={workflowService}
            onShowModal={setUpdateNewWorkflowVisibility}
            product={props.product}
            name={workflowName}
            updateWorkflowUuid={props.workflowUuid}
            version={props.version}
            entityType={props.entityType}
        />
    );

    const deduceActionType = (): ActionType => {
        if (props.isCreate) {
            return "create";
        } else if (props.isEdit) {
            return "edit";
        } else {
            return "update";
        }
    };

    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    let content = <></>;
    switch (dialogState) {
        case DialogState.FETCHING_WORKFLOW_EDITORS:
            content = (
                <div className={props.isCreate ? style.fetchStatus : style.fetchEditorStatus}>
                    <LoadingIndicator />
                    <div className={style.loadingMessage}>
                        {t("ManageWorkflowDialog.fetchWorkflowEditors.loadingMessage")}
                    </div>
                    {confirmationModal}
                </div>
            );
            break;
        case DialogState.FETCHING_WORKFLOW:
            content = (
                <div className={props.isCreate ? style.fetchStatus : style.fetchEditorStatus}>
                    <LoadingIndicator />
                    <div className={style.loadingMessage}>{t("ManageWorkflowDialog.fetchWorkflow.loadingMessage")}</div>
                    {confirmationModal}
                </div>
            );
            break;
        case DialogState.FETCHING_WORKFLOW_EDITORS_FAILED:
            content = (
                <div className={props.isCreate ? style.fetchStatus : style.fetchEditorStatus}>
                    <div className={style.failureMessage}>
                        {t("ManageWorkflowDialog.fetchWorkflowEditors.failureMessage")}
                    </div>
                    {confirmationModal}
                </div>
            );
            break;
        case DialogState.FETCHING_WORKFLOW_FAILED:
            content = (
                <div className={props.isCreate ? style.fetchStatus : style.fetchEditorStatus}>
                    <div className={style.failureMessage}>{t("ManageWorkflowDialog.fetchWorkflow.failureMessage")}</div>
                    {confirmationModal}
                </div>
            );
            break;
        case DialogState.EDITING:
        case DialogState.ASYNC_SAVING_WORKFLOW:
        case DialogState.ASYNC_SAVING_WORKFLOW_SUCCEEDED:
        case DialogState.ASYNC_SAVING_WORKFLOW_FAILED:
            content =
                props.editorGeneration == "V2" ? (
                    <>
                        <WasmVisualEditorView
                            key={workflowName}
                            profile={
                                workflow
                                    ? extractWorkflowProfile(workflow)
                                    : selectedProfile
                                    ? selectedProfile
                                    : (props.product as Profile)
                            }
                            version={
                                workflow ? workflow.version : props.isCreate ? selectedVersion : selectedNewVersion
                            }
                            workflowEditors={workflowEditors}
                            uuid={workflowUuid || props.updateWorkflowUuid}
                            setWorkflow={handleWorkflowChangeWithNameSync}
                            data-testid={testIds.workArea.workflows.manageWorkflowDialog.visualEditor.itself}
                            workflowName={workflowNameInputValue}
                            actionType={deduceActionType()}
                            entityType={props.entityType}
                            bmdeDiagnostics={selectedProfile}
                            preventNameChange={preventNameEdit}
                        />
                        {confirmationModal}
                    </>
                ) : (
                    <>
                        <ReactTooltip type="light" className={tableStyle.tooltip} clickable={true} />
                        <div className={style.workflowPropertiesContainer}>
                            <div>
                                <label htmlFor="workflowName" className={form.label}>
                                    {t("ManageWorkflowDialog.workflowName")}
                                </label>
                                <input
                                    autoFocus
                                    id="workflowName"
                                    data-testid={testIds.workArea.workflows.manageWorkflowDialog.nameInput}
                                    type="text"
                                    value={workflowNameInputValue}
                                    className={classNames(form.input, style.workflowNameInput)}
                                    onChange={handleWorkflowNameInputChange}
                                    onBlur={handleWorkflowNameChange}
                                    onKeyPress={handleWorkflowNameInputKeyDown}
                                    maxLength={WORKFLOW_NAME_MAX_LENGTH}
                                    disabled={!props.isCreate && preventNameEdit}
                                />
                                <span className={style.inputErrorMessage}>
                                    {backendValidationErrors}
                                    {frontendValidationError}
                                </span>
                            </div>
                            {!props.isCreate && showUpdateOldGenStatusInStore && newWorkflowEditor && (
                                <div className={style.editorUpdateBanner}>
                                    <span className={style.localBannerInfoIcon}>
                                        <Info
                                            borderColor={theme.contentBackgroundColor}
                                            color={theme.localBannerTextColor}
                                        />
                                    </span>

                                    {t("ManageWorkflowDialog.newWorkflowAvailableBanner")}
                                    <div className={style.linkContaner}>
                                        <button
                                            className={style.link}
                                            data-testid={
                                                testIds.workArea.workflows.manageWorkflowDialog.bannerLink.updateLink
                                            }
                                            onClick={() => {
                                                usageStatisticsService.sendEvent({
                                                    category: Category.NEW_WORKFLOW_EDITOR,
                                                    action: Action.UPDATE_WORKFLOW_INITIATED,
                                                });
                                                setUpdateNewWorkflowVisibility(true);
                                            }}
                                        >
                                            {t("Common.update")}
                                        </button>
                                        <div className={style.divider} />
                                        <button
                                            className={style.link}
                                            data-testid={
                                                testIds.workArea.workflows.manageWorkflowDialog.bannerLink
                                                    .maybeLaterLink
                                            }
                                            onClick={() => {
                                                usageStatisticsService.sendEvent({
                                                    category: Category.NEW_WORKFLOW_EDITOR,
                                                    action: Action.UPDATE_WORKFLOW_POSTPONED,
                                                });
                                                updateShowOldGenUpdateStatus();
                                            }}
                                        >
                                            {t("ManageWorkflowDialog.link.maybeLaterAction")}
                                        </button>
                                    </div>
                                </div>
                            )}
                            {loading && (
                                <div>
                                    <LoadingIndicator small={true} />
                                </div>
                            )}
                            {(!userDetails?.showUpdateOldGenWorkflow || showUpdateWorkflowDialogButton) &&
                                newWorkflowEditor &&
                                !props.isCreate && (
                                    <button
                                        className={buttons.secondaryButtonWithoutIcon}
                                        data-testid={
                                            testIds.workArea.workflows.manageWorkflowDialog.updateWorkflowButton
                                        }
                                        onClick={() => {
                                            usageStatisticsService.sendEvent({
                                                category: Category.NEW_WORKFLOW_EDITOR,
                                                action: Action.UPDATE_WORKFLOW_INITIATED,
                                            });
                                            setUpdateNewWorkflowVisibility(true);
                                        }}
                                    >
                                        {t("ManageWorkflowDialog.updateWorkflowButton")}
                                    </button>
                                )}
                        </div>
                        <Tabs
                            onSelect={handleTabSelect}
                            className={style.tabs}
                            selectedTabPanelClassName={style.selectedTab}
                        >
                            <TabList>
                                <Tab data-testid={testIds.workArea.workflows.manageWorkflowDialog.visualEditor.tab}>
                                    {t("ManageWorkflowDialog.visualEditor.title")}
                                </Tab>
                                <Tab data-testid={testIds.workArea.workflows.manageWorkflowDialog.jsonEditor.tab}>
                                    {t("ManageWorkflowDialog.jsonEditor.title")}
                                </Tab>
                                <Tab data-testid={testIds.workArea.workflows.manageWorkflowDialog.versionList.tab}>
                                    {t("ManageWorkflowDialog.versionList.title")}
                                </Tab>
                            </TabList>
                            <TabPanel>
                                {/* The component needs to be created and destroyed based on the tab visibility */}
                                {TabIndex.VISUAL_EDITOR === selectedTab && (
                                    <VisualEditorView
                                        key={workflowName}
                                        profile={
                                            workflow
                                                ? extractWorkflowProfile(workflow)
                                                : selectedProfile
                                                ? selectedProfile
                                                : Profile.BDE
                                        }
                                        version={workflow ? workflow.version : selectedVersion}
                                        workflowEditorReference={workflowEditor}
                                        workflowEditors={workflowEditors}
                                        workflow={workflow}
                                        setWorkflow={handleWorkflowChangeWithNameSync}
                                        data-testid={
                                            testIds.workArea.workflows.manageWorkflowDialog.visualEditor.itself
                                        }
                                    />
                                )}
                            </TabPanel>
                            <TabPanel>
                                {/* The component needs to be created and destroyed based on the tab visibility */}
                                {TabIndex.JSON_EDITOR === selectedTab && (
                                    <JsonEditorView
                                        workflow={workflow}
                                        setWorkflow={handleWorkflowChange}
                                        data-testid={testIds.workArea.workflows.manageWorkflowDialog.jsonEditor.itself}
                                    />
                                )}
                            </TabPanel>
                            <TabPanel>
                                {TabIndex.VERSION_LIST === selectedTab && (
                                    <div
                                        className={style.tableContainer}
                                        data-testid={testIds.workArea.workflows.manageWorkflowDialog.versionList.itself}
                                    >
                                        {versions.length ? (
                                            <StaticTable
                                                headers={[
                                                    {
                                                        className: staticTableStyle.firstColumnHeader,
                                                        value: t("ManageWorkflowDialog.versionList.versionTable.date"),
                                                    },
                                                    {
                                                        value: t(
                                                            "ManageWorkflowDialog.versionList.versionTable.editor"
                                                        ),
                                                    },
                                                ]}
                                                cells={versions.map((list, index) => {
                                                    return [
                                                        <div key={"date" + index}>
                                                            <a
                                                                onClick={() => {
                                                                    setPreviousVersionConfirmation(true);
                                                                    setPreviousVersionToConfirm(list);
                                                                }}
                                                            >
                                                                <DateCell value={list.modified} />
                                                            </a>
                                                        </div>,

                                                        <div key={"editor" + index}>{list.modifiedBy}</div>,
                                                    ];
                                                })}
                                            />
                                        ) : (
                                            t("ManageWorkflowDialog.versionList.emptyListMessage")
                                        )}
                                    </div>
                                )}
                            </TabPanel>
                        </Tabs>
                        <div
                            className={style.asyncSaveStatus}
                            data-test-id={testIds.workArea.workflows.manageWorkflowDialog.saveStatusLabel}
                        >
                            {saveStatus}
                        </div>
                        <div className={classNames(style.buttonContainer)}>
                            <button
                                className={buttons.primaryButtonWithoutIcon}
                                onClick={handleSaveClick}
                                disabled={editingActionsDisabled}
                                data-testid={testIds.workArea.workflows.manageWorkflowDialog.saveButton}
                            >
                                <TextWithTooltip
                                    text={
                                        editingActionsDisabled
                                            ? t("ManageWorkflowDialog.tooltip.saveDisabled")
                                            : t("ManageWorkflowDialog.tooltip.save")
                                    }
                                >
                                    {t("Common.save")}
                                </TextWithTooltip>
                            </button>

                            <button
                                className={buttons.secondaryButtonWithoutIcon}
                                onClick={() => setSaveAsWorkflow(true)}
                                disabled={editingActionsDisabled}
                                data-testid={testIds.workArea.workflows.manageWorkflowDialog.saveAsButton}
                            >
                                <TextWithTooltip
                                    text={
                                        editingActionsDisabled
                                            ? t("ManageWorkflowDialog.tooltip.saveAsDisabled")
                                            : t("ManageWorkflowDialog.tooltip.saveAs")
                                    }
                                >
                                    {t("Common.saveAs")}
                                </TextWithTooltip>
                            </button>

                            <button
                                className={buttons.secondaryButtonWithoutIcon}
                                onClick={handleSaveCloseClick}
                                disabled={editingActionsDisabled}
                                data-testid={testIds.workArea.workflows.manageWorkflowDialog.saveCloseButton}
                            >
                                <TextWithTooltip
                                    text={
                                        editingActionsDisabled
                                            ? t("ManageWorkflowDialog.tooltip.saveCloseDisabled")
                                            : t("ManageWorkflowDialog.tooltip.saveClose")
                                    }
                                >
                                    {t("Common.saveClose")}
                                </TextWithTooltip>
                            </button>
                        </div>
                        {handleSaveAsDialogs}
                        {confirmationModal}
                        {handleVersionChanged}
                        {updateNewWorkflow}
                    </>
                );
            break;
        case DialogState.SELECTING_WORKFLOW_EDITOR:
            content = (
                <Formik
                    initialValues={{ product: "", version: "", name: "" }}
                    onSubmit={confirmWorkflowEditorSelection}
                    validationSchema={object().shape({
                        name: string()
                            .required(t("WorkflowsTable.actions.create.validation.nameRequired"))
                            .max(WORKFLOW_NAME_MAX_LENGTH, t("WorkflowsTable.actions.create.validation.nameMaxLength"))
                            .test(
                                "Has only valid characters",
                                t("WorkflowsTable.actions.create.validation.nameSpecialCharacters"),
                                (value) => hasOnlyValidWorkflowCharacters(value)
                            ),
                        product: string().required(t("WorkflowsTable.actions.create.validation.productRequired")),
                        version: string().required(t("WorkflowsTable.actions.create.validation.versionRequired")),
                    })}
                    validateOnChange={false}
                >
                    {({
                        values,
                        errors,
                        handleChange,
                        validateField,
                        validateForm,
                        setFieldValue,
                    }: FormikProps<CreateWorkflowFormValues>) => {
                        return (
                            <Form>
                                <div>
                                    {props.entityType === "WORKFLOW" && (
                                        <div className={style.localBanner}>
                                            <span className={style.localBannerInfoIcon}>
                                                <Info
                                                    borderColor={theme.contentBorderColor}
                                                    color={theme.infoIconBackgroundColor}
                                                />
                                            </span>
                                            <>
                                                {t("ManageWorkflowDialog.selectWorkflowEditor.introductionText", {
                                                    customerName: props.tenantName ?? "",
                                                })}
                                            </>
                                        </div>
                                    )}

                                    <div className={classNames(form.formFields, style.formField)}>
                                        <div>
                                            <label
                                                htmlFor="product"
                                                className={classNames(form.label, style.fixedWidthLabel)}
                                            >
                                                {t("ManageWorkflowDialog.selectWorkflowEditor.productLabel")}
                                            </label>
                                        </div>

                                        <div>
                                            <select
                                                autoFocus
                                                name="product"
                                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                    handleChange(e);
                                                    setFieldValue("version", "");
                                                    setSelectedVersion("");
                                                    handleSelectedProfileChanged(e);
                                                }}
                                                onBlur={() => errors.product && validateField("product")}
                                                className={classNames(form.select, style.fixedWidthProduct)}
                                                value={values.product}
                                                data-testid={
                                                    testIds.workArea.workflows.manageWorkflowDialog
                                                        .productVersionSelection.productSelect
                                                }
                                            >
                                                <option key={"empty"} value={""}>
                                                    {t("ManageWorkflowDialog.selectWorkflowEditor.selectProduct")}
                                                </option>
                                                {Object.values(Profile).map((each, index) => {
                                                    if (
                                                        Profile.ALL !== each &&
                                                        tenantDetails.featureLicenses.includes(
                                                            profileToFeatureLicenseMap.get(each) as FeatureLicenseType
                                                        )
                                                    ) {
                                                        return (
                                                            <option key={index} value={each}>
                                                                {profileToNameMap.get(each)}
                                                            </option>
                                                        );
                                                    }
                                                })}
                                            </select>
                                            <div className={classNames(form.error, form.errorMultiLine)}>
                                                {errors.product}
                                            </div>
                                        </div>
                                        <div>
                                            <select
                                                name="version"
                                                value={values.version}
                                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                    handleChange(e);
                                                    handleSelectedVersionChanged(e);
                                                }}
                                                onBlur={() => errors.version && validateField("version")}
                                                className={classNames(form.select, style.fixedWidthVersion)}
                                                disabled={selectedProfile === ""}
                                                data-testid={
                                                    testIds.workArea.workflows.manageWorkflowDialog
                                                        .productVersionSelection.versionSelect
                                                }
                                            >
                                                <option key={"value"} value={""}>
                                                    {t("ManageWorkflowDialog.selectWorkflowEditor.selectVersion")}
                                                </option>
                                                {selectedProfile !== "" && optionList}
                                            </select>
                                            <div className={classNames(form.error, form.errorMultiLine)}>
                                                {errors.version}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classNames(form.formFields, style.formField)}>
                                        <div>
                                            <label
                                                htmlFor="product"
                                                className={classNames(form.label, style.fixedWidthLabel)}
                                            >
                                                {t("Common.name")}
                                            </label>
                                        </div>
                                        <div>
                                            <input
                                                id="versionSelectWorkflowName"
                                                name="name"
                                                data-testid={testIds.workArea.workflows.manageWorkflowDialog.nameInput}
                                                type="text"
                                                className={classNames(form.input, style.fixedWidthInput)}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    handleChange(e);
                                                    handleWorkflowNameInputChange(e);
                                                }}
                                                onKeyUp={() => validateField("name")}
                                                maxLength={WORKFLOW_NAME_MAX_LENGTH}
                                            />

                                            <div className={classNames(form.error, form.errorMultiLine)}>
                                                {errors.name}
                                            </div>
                                        </div>
                                    </div>

                                    {(errors.name || errors.version || errors.product) && (
                                        <div className={classNames(form.errorMultiLine, style.commonError)}>
                                            <span className={style.localBannerInfoIcon}>
                                                <FailedRedNotificationIcon
                                                    backgroundColor={theme.bannerIconBackgroundColor}
                                                    iconColor={theme.bannerIconColor}
                                                />
                                            </span>
                                            {t("ManageWorkflowDialog.selectWorkflowEditor.commonValidationError")}
                                        </div>
                                    )}
                                    <div className={classNames(form.buttonContainer)}>
                                        <button
                                            type="submit"
                                            className={buttons.primaryButtonWithoutIcon}
                                            data-testid={
                                                testIds.workArea.workflows.manageWorkflowDialog.productVersionSelection
                                                    .designWorkflowButton
                                            }
                                            onClick={() => validateForm().then(() => confirmWorkflowEditorSelection)}
                                        >
                                            {t("ManageWorkflowDialog.selectWorkflowEditor.designWorkflowButton")}
                                        </button>
                                    </div>
                                </div>
                                {confirmationModal}
                            </Form>
                        );
                    }}
                </Formik>
            );
            break;
        case DialogState.SELECTING_NEW_WORKFLOW_EDITOR:
            content = (
                <Formik
                    initialValues={{ product: props.product, version: "", name: "" }}
                    onSubmit={confirmNewWorkflowEditorSelection}
                    validationSchema={object().shape({
                        name: string()
                            .required(t("WorkflowsTable.actions.create.validation.nameRequired"))
                            .max(WORKFLOW_NAME_MAX_LENGTH, t("WorkflowsTable.actions.create.validation.nameMaxLength"))
                            .test(
                                "Has only valid characters",
                                t("WorkflowsTable.actions.create.validation.nameSpecialCharacters"),
                                (value) => hasOnlyValidWorkflowCharacters(value)
                            ),
                    })}
                    validateOnChange={true}
                    validateOnBlur={true}
                >
                    {({ errors, handleChange, validateField, validateForm }: FormikProps<CreateWorkflowFormValues>) => {
                        return (
                            <Form>
                                <div className={style.smallNewEditorDialog}>
                                    <div className={style.updateBanner}>
                                        <span className={style.localBannerInfoIcon}>
                                            <Info
                                                borderColor={theme.chartNeutralColor}
                                                color={theme.localBannerTextColor}
                                            />
                                        </span>
                                        <div>{t("ManageWorkflowDialog.updateWorkflowDialog.introductionText")}</div>
                                    </div>

                                    <div className={classNames(form.formFields, style.formField)}>
                                        <div>
                                            <label
                                                htmlFor="product"
                                                className={classNames(
                                                    form.label,
                                                    style.fixedWidthLabel,
                                                    style.updateWorkflowFormLabel
                                                )}
                                            >
                                                {t("ManageWorkflowDialog.selectWorkflowEditor.productLabel")}
                                            </label>
                                        </div>
                                        <div className={style.profileContainer}>
                                            {profileToNameMap.get(props.product as Profile)}
                                        </div>
                                    </div>
                                    <div className={classNames(form.formFields, style.formField)}>
                                        <div>
                                            <label
                                                htmlFor="version"
                                                className={classNames(
                                                    form.label,
                                                    style.fixedWidthLabel,
                                                    style.updateWorkflowFormLabel
                                                )}
                                            >
                                                {t("ManageWorkflowDialog.selectWorkflowEditor.editorVersionLabel")}
                                            </label>
                                        </div>
                                        {updateWorkflowEditors.length === 1 ? (
                                            <div>{updateWorkflowEditors[0].version}</div>
                                        ) : (
                                            <div>
                                                <select
                                                    name="version"
                                                    className={classNames(form.select, style.fixedWidthVersion)}
                                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                        handleChange(e);
                                                        handleSelectedNewVersionChanged(e);
                                                    }}
                                                    data-testid={
                                                        testIds.workArea.workflows.manageWorkflowDialog
                                                            .UpdateWorkflowSelection.versionSelect
                                                    }
                                                >
                                                    {updateOptionList}
                                                </select>
                                            </div>
                                        )}
                                    </div>

                                    <div className={classNames(form.formFields, style.formField)}>
                                        <div>
                                            <label
                                                htmlFor="name"
                                                className={classNames(
                                                    form.label,
                                                    style.fixedWidthLabel,
                                                    style.updateWorkflowFormLabel
                                                )}
                                            >
                                                {t("Common.name")}
                                            </label>
                                        </div>
                                        <div>
                                            <input
                                                id="versionSelectWorkflowName"
                                                name="name"
                                                type="text"
                                                className={classNames(form.input, style.fixedWidthName)}
                                                data-testid={
                                                    testIds.workArea.workflows.manageWorkflowDialog
                                                        .UpdateWorkflowSelection.nameInput
                                                }
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    handleChange(e);
                                                    handleWorkflowNameInputChange(e);
                                                }}
                                                onKeyUp={() => validateField("name")}
                                                maxLength={WORKFLOW_NAME_MAX_LENGTH}
                                            />

                                            <div className={classNames(form.error, form.errorMultiLine)}>
                                                {errors.name}
                                            </div>
                                        </div>
                                    </div>
                                    {errors.name && (
                                        <div className={classNames(form.errorMultiLine, style.commonErrorNewEditor)}>
                                            <span className={style.localBannerInfoIcon}>
                                                <FailedRedNotificationIcon
                                                    backgroundColor={theme.bannerIconBackgroundColor}
                                                    iconColor={theme.bannerIconColor}
                                                />
                                            </span>
                                            {t("ManageWorkflowDialog.selectWorkflowEditor.commonValidationError")}
                                        </div>
                                    )}
                                    <div className={classNames(form.buttonContainer, style.buttonContainer)}>
                                        <button
                                            type="submit"
                                            className={buttons.primaryButtonWithoutIcon}
                                            data-testid={
                                                testIds.workArea.workflows.manageWorkflowDialog.UpdateWorkflowSelection
                                                    .openNewWorkflowButton
                                            }
                                            onClick={() => {
                                                usageStatisticsService.sendEvent({
                                                    category: Category.NEW_WORKFLOW_EDITOR,
                                                    action: Action.OPEN_OLD_WORKFLOW_IN_NEW_EDITOR,
                                                });
                                                validateForm().then(() => confirmNewWorkflowEditorSelection);
                                            }}
                                        >
                                            {t("Common.update")}
                                        </button>
                                        <button
                                            type="reset"
                                            className={buttons.secondaryButtonWithoutIcon}
                                            data-testid={testIds.common.dialog.closeButton}
                                            onClick={() => {
                                                props.onCancel(true);
                                            }}
                                        >
                                            {t("Common.noDontUpdateButton")}
                                        </button>
                                    </div>
                                </div>
                                {confirmationModal}
                                {updateNewWorkflow}
                            </Form>
                        );
                    }}
                </Formik>
            );
            break;
        case DialogState.SYNC_SAVING_WORKFLOW:
            content = (
                <div className={style.syncSaveStatus}>
                    <LoadingIndicator />
                    <div className={style.loadingMessage}>{t("ManageWorkflowDialog.saveWorkflow.loadingMessage")}</div>
                    {confirmationModal}
                </div>
            );
            break;
        case DialogState.SYNC_SAVING_WORKFLOW_SUCCEEDED:
            content = (
                <>
                    <div className={style.syncSaveStatus}>
                        <div className={style.successMessage}>
                            {t("ManageWorkflowDialog.saveWorkflow.successMessage")}
                        </div>
                    </div>
                    <div className={classNames(style.buttonContainer)}>
                        <button
                            className={buttons.primaryButtonWithoutIcon}
                            data-testid={testIds.common.dialog.closeButton}
                            onClick={() => {
                                props.onCancel(true);
                                props.onUpdateParentView();
                            }}
                        >
                            {t("Common.close")}
                        </button>
                    </div>
                    {confirmationModal}
                </>
            );
            break;
        case DialogState.SYNC_SAVING_WORKFLOW_FAILED:
            content = (
                <>
                    <div className={style.syncSaveStatus}>
                        <div className={style.failureMessage}>
                            {saveError !== undefined
                                ? typeof saveError === "string"
                                    ? saveError
                                    : t("ManageWorkflowDialog.saveWorkflow.failureMessageDetails", saveError)
                                : t("ManageWorkflowDialog.saveWorkflow.failureMessage")}
                        </div>
                    </div>
                    <div className={classNames(style.buttonContainer)}>
                        <button
                            className={buttons.secondaryButtonWithoutIcon}
                            data-testid={testIds.workArea.workflows.manageWorkflowDialog.saveFailed.backToEditorButton}
                            onClick={handleBackToEditorClick}
                        >
                            {t("ManageWorkflowDialog.saveWorkflow.backToEditorButton")}
                        </button>
                        <button
                            className={buttons.primaryButtonWithoutIcon}
                            data-testid={testIds.common.dialog.closeButton}
                            onClick={() => props.onCancel(true)}
                        >
                            {t("Common.close")}
                        </button>
                    </div>
                    {confirmationModal}
                </>
            );
            break;
    }

    return content;
};

export default connector(ManageWorkflowDialog);
