import { TableData } from "./table";

export const EVENT_TYPE_DELIVERED = "DELIVERED";
export const EVENT_TYPE_CONSUMED = "CONSUMED";
export const EVENT_TYPE_WITHDRAWN = "WITHDRAWN";
export const EVENT_TYPE_ADDED = "ADDED";
export const EVENT_TYPE_DELETED = "DELETED";
export const EVENTS = [
    EVENT_TYPE_CONSUMED,
    EVENT_TYPE_DELIVERED,
    EVENT_TYPE_WITHDRAWN,
    EVENT_TYPE_ADDED,
    EVENT_TYPE_DELETED,
];
export type EventType = typeof EVENTS[number];

export enum EventActions {
    ENTITLEMENT_CREATION = "ENTITLEMENT_CREATION",
    FEATURE_LICENSE_CONSUMPTION = "FEATURE_LICENSE_CONSUMPTION",
    LICENSE_CONSUMPTION = "LICENSE_CONSUMPTION",
    LICENSE_DELIVERY = "LICENSE_DELIVERY",
    LICENSE_DELIVERY_ASSIGNED = "LICENSE_DELIVERY_ASSIGNED",
    LICENSE_DELIVERY_RECEIVED = "LICENSE_DELIVERY_RECEIVED",
    LICENSE_KEY_CONSUMPTION = "LICENSE_KEY_CONSUMPTION",
    LICENSE_KEY_CREATION = "LICENSE_KEY_CREATION",
    SECURE_LICENSE_CONSUMPTION = "SECURE_LICENSE_CONSUMPTION",
    TENANT_DELETION = "TENANT_DELETION",
    LICENSE_POOL_UPDATE = "LICENSE_POOL_UPDATE",
    LICENSING_MODEL_CHANGE = "LICENSING_MODEL_CHANGE",
}

export const EVENT_ACTIONS: string[] = [
    EventActions.ENTITLEMENT_CREATION,
    EventActions.FEATURE_LICENSE_CONSUMPTION,
    EventActions.LICENSE_CONSUMPTION,
    EventActions.LICENSE_DELIVERY,
    EventActions.LICENSE_DELIVERY_ASSIGNED,
    EventActions.LICENSE_DELIVERY_RECEIVED,
    EventActions.LICENSE_KEY_CONSUMPTION,
    EventActions.LICENSE_KEY_CREATION,
    // EventActions.SECURE_LICENSE_CONSUMPTION, // Temporarily removed from the UI
    EventActions.TENANT_DELETION,
    EventActions.LICENSE_POOL_UPDATE,
    EventActions.LICENSING_MODEL_CHANGE,
];
export type EventAction = typeof EVENT_ACTIONS[number];

export interface LicenseEvent {
    eventUuid: string;
    eventDate: string;
    productId: string;
    eventType: EventType;
    change: number;
    remaining: number;
    expirationDate: string;
    user: string;
    poolName: string;
    eventAction: EventAction;
    notes?: string;
    licenseConsumptionId?: string;
    assetId?: string;
    targetedTenant?: string;
    amountBefore?: number;
    amountAfter?: number;
    usedAmount?: number;
}

export interface LicenseEventQuery {
    search: string;
    from: string;
    to: string;
    productIds: string[];
    actions: string[];
    eventActions: string[];
    cursor?: KeyCursor;
}

export interface KeyCursor {
    eventUuid: string;
    tenantUuid: string;
    time: string;
}

export interface LicenseEventResult extends TableData {
    licenseEvents: LicenseEvent[];
    cursor?: KeyCursor;
}

export interface TableState {
    licenseEvents: LicenseEvent[];
    cursor?: KeyCursor;
    scrollPosition: number;
}

export interface FilterState {
    startDate: string;
    endDate: string;
    eventTypes: EventType[];
    quickSearchFilter: string;
    productIds: string[];
    actions: string[];
}

export const DELIVERY_ACTIONS: string[] = [
    EventActions.LICENSE_DELIVERY,
    EventActions.LICENSE_KEY_CREATION,
    EventActions.ENTITLEMENT_CREATION,
    EventActions.LICENSE_DELIVERY_RECEIVED,
    EventActions.LICENSE_DELIVERY_ASSIGNED,
];

export const WITHDRAWN_ACTIONS: string[] = [EventActions.TENANT_DELETION, EventActions.LICENSE_POOL_UPDATE];

export const CONSUMPTION_ACTIONS: string[] = [
    EventActions.FEATURE_LICENSE_CONSUMPTION,
    EventActions.LICENSE_CONSUMPTION,
    EventActions.LICENSE_KEY_CONSUMPTION,
    //EventActions.SECURE_LICENSE_CONSUMPTION, // Temporarily removed from the UI
];

export const DELETE_ACTIONS: string[] = [EventActions.LICENSING_MODEL_CHANGE];

export const ADD_ACTIONS: string[] = [EventActions.LICENSE_POOL_UPDATE];
